import React, { useState, useEffect, useRef } from 'react';
import './Terminal.css'; 

interface CommandsProps {
  [key: string]: string;
}

interface GameStateProps {
  location: string;
  inventory: string[];
  playing: boolean
  isShedUnlocked: boolean;
}

export default function TerminalPrompt() {
  const [commandHistory, setCommandHistory] = useState<string[]>([
    `
    
    
                               
 _____       _     _         _ 
|_   _|_ _ _|_|___| |_ ___ _| |
  | | | | | | |_ -|  _| -_| . |
  |_| |_____|_|___|_| |___|___|
                               
                               
 _____                         
|_   _|___ ___ ___             
  | | |  _| -_| -_|            
  |_| |_| |___|___|            
                               
                               
 _____                   _     
| __  |___ ___ ___ ___ _| |___ 
|    -| -_|  _| . |  _| . |_ -|
|__|__|___|___|___|_| |___|___|
                               

`
  ]);
  const [currentInput, setCurrentInput] = useState<string>('');
  const terminalEndRef = useRef<HTMLInputElement>(null);
  const [gameState, setGameState] = useState<GameStateProps>({
    location: 'allotment',
    inventory: [],
    playing: false,
    isShedUnlocked: false
  });
  const [isRebooting, setIsRebooting] = useState(false);
  const rebootIntervalRef = useRef<NodeJS.Timeout>();

  const getRandom1980sNewYearsEveDate = (): string => {
    const year = 1980 + Math.floor(Math.random() * 10); // Random year between 1980 and 1989
    const currentTime = new Date();
    currentTime.setFullYear(year, 11, 31); // Set to December 31st of the random year
    return currentTime.toString();
  };

  const commands: CommandsProps = {
    help: 'Available commands: clear, date, hello, help, reboot, zork',
    hello: 'Hello, traveller from the future!',
    date: getRandom1980sNewYearsEveDate(),
    clear: 'clear',
    zork: 'Welcome to Porky and the Marrow Snatchers 🍆 v0.1 by Alan Tooby! Type "zork" for game commands.',
    reboot: 'Initiating system reboot...'
  };

  const locations: Record<string, { description: string; item: string }> = {
    allotment: {
      description: "You are at your allotment. Your prize marrow is missing! \nYou see your shed to the west.\nTo the south is the street.",
      item: '',
    },
    shed: {
      description: "You are in a shed. The air is thick with the smell of mothballs and disappointment.  You are surrounded by tools, gardening equipment, empty beer cans and back issues of Juicy Plums magazine.\nTo the east is the allotment.",
      item: 'Marrow',
    },
    whitehouse: {
      description: "You are on Whitehouse Way, your whiskey-soaked mind recalls that you used to court a girl called Mary that lived on this street.  To the north is the allotment. The street stretches to the east and west.  Whilst further south is the town centre.",
      item: '',
    },
    currycorner: {
      description: "You are on a street whose street sign fell away many years ago, and the council have never replaced it.  Even the locals have forgotten what the real name is, you all jsut call it Curry Corner.  \nThe smell of spices and hot oil fills your nostrils.  \nThe street stretches to the east and curves (obviously, because it's a corner) away to the south.  \nOne of the restaurants catches your eye, it is called 'Joy Bangla', and if you weren't currently living in the 1980's you could check out their website at 🌐 joybangla.menu .",
      item: '',
    },
    house: {
      description: "You are standing in the living room of your house.  A needlework sampler hangs on the wall, it reads 🏠 'Home Sweet Home'.  \nThe garden is to the east, the exit back to the street is to the west. The side door leads out to the 🦆 duck pond in the park.",
      item: 'coins',
    },
    garden: {
      description: "You are in your back garden, aaah, no one has been there for many a year.  The grass is overgrown and the flower beds are choked with weeds, well you are more of a veggie and allotment man.  \nA path leads to the west back to the house.\nIn the corner of the garden is you attempt at a compost heap 💩, it is more of a 🐀 rat hotel than a compost heap, you've not paid it much attention there could be anything in there, you're not going to go digging about in there with your bare hands.",
      item: '',
    },
    gardencentre: {
      description: "You are in Garys Garden Centre, the owner is busy kicking a ⚽ football against the counter.  The smell of fresh cut flowers fills the air.  The centre is full of plants, tools and garden ornaments.  \nYou could spend hours here, but you have a marrow to find.\nTo the north is Curry Corner.",
      item: 'spade'
    },
    highstreet: {
      description: "You are on the high street.  This is the 1980s, so there are actually still shops 🛒, and no windows boarded up.\nTo the north is the street, east is the park, and south is the town hall.",
      item: '',
    },
    duckpond: {
      description: "You are at the duck pond.  The 🦆 ducks are quacking and the swans are hissing.  You see a sign that reads 🪧 'Do not feed the ducks bread 🍞, it makes them sick'.  \nTo the west is the High Street, you can also see your house to the north.",
      item: 'bread',
    },
    townhall: {
      description: "You are at the town hall 🏫.  The building is a grand old building, built in the 1800's, it has a clock tower, but the clock is stuck and hasn't moved since hit by 🌩️ lightening in 1955.  \nTo the north is the high street.\nThe town hall is busy setting up for the big vegetable show, you can see an empty table with a name plaque on it, it reads 'Porky', this is where your marrow should be.",
      item: '',
    },
    dead: {
      description: 'You are dead. Game over man, game over.\nYou have missed your chance to solve the game and find the big clue at the end.',
      item: '',
    },
  };

    useEffect(() => {
        return () => {
          if (rebootIntervalRef.current) {
            clearInterval(rebootIntervalRef.current);
          }
        };
      }, []);

  const handleCommand = (input: string) => {
    let output: string = '';
    const args = input.split(' ');
    const command = args[0].toLowerCase();

    if (command === 'reboot') {
        setIsRebooting(true);
        setCommandHistory([]);
        setCommandHistory((prev) => [...prev, '> reboot', 'Initiating system reboot...']);
        
        // Set background and text color
        document.body.style.backgroundColor = 'red';
        document.body.style.color = 'white';
        
        // Start generating random codes
        rebootIntervalRef.current = setInterval(() => {
          const randomCode = Math.random().toString(36).substring(2, 15).toUpperCase() + 
                           ' ' + Math.random().toString(36).substring(2, 15).toUpperCase();
          setCommandHistory(prev => [...prev, randomCode]);
        }, 100);
        
        return;
      }


    if (command === 'zork') {
      if(!gameState.playing) {
          output = `Welcome to Porky and the Marrow Snatchers 🍆 v0.1 by Alan Tooby! Type "zork" for game commands.\nYou are Porkchop 🥩, known to his friends as Porky, drinker 🍺, darts player 🎯, vegetable grower 🫑, skillful lover 🍆.\nAfter a heavy night in the Black Swan you have awoken in the cabbage patch in your allotment.\nToday is the day of the big vegetable show, 🏫 and you have a prize marrow bound to win first prize. 🏆\nOr at least you did have, looking around it is missing, due to the drink you don't know if you've misplaced it, or if it has been stolen.  Can you find it?`
          setGameState({ ...gameState, playing: true });
      } else {
          output = 'Game commands: go [direction], look, take [item], inventory, use [item]';
      }
    } 

    
    if (command === 'look') {
      output = locations[gameState.location]?.description || 'You see nothing of interest.';
    }

    if (command === 'go') {
      const direction = args[1].toLowerCase()
      if (!direction) {
        output = 'Go where?';
      }
      let newLocation = gameState.location;

      if (gameState.location === 'allotment'){ // South, West
        if (direction === 'west') {
          if(gameState.isShedUnlocked) {
            newLocation = 'shed';
            output = locations.shed.description;
          } else {
            output = 'The shed is locked. You need to find the key.';
          }
        } 
        if (direction === 'south') {
          newLocation = 'whitehouse';
          output = locations.whitehouse.description;
        }
      }

      if (gameState.location === 'shed'){ // East
        if (direction === 'east') {
          newLocation = 'allotment';
          output = locations.allotment.description;
        } 
      }

      if(gameState.location === 'whitehouse'){ // North, East, West, South
        if (direction === 'north') {
          newLocation = 'allotment';
          output = locations.allotment.description;
        } 
        if (direction === 'west') {
          newLocation = 'currycorner';
          output = locations.currycorner.description;
        }
        if (direction === 'east') {
          newLocation = 'house';
          output = locations.house.description;
        }
        if (direction === 'south') {
          newLocation = 'highstreet';
          output = locations.highstreet.description;
        }
      }

      if(gameState.location === 'house'){ // East, West
        if (direction === 'east') {
          newLocation = 'garden';
          output = locations.garden.description;
        }
        if (direction === 'west') {
          newLocation = 'whitehouse';
          output = locations.whitehouse.description;
        }
        if (direction === 'south') {
          newLocation = 'duckpond';
          output = locations.duckpond.description;
        }
      }

      if(gameState.location === 'garden'){ // West
        if (direction === 'west') {
          newLocation = 'house';
          output = locations.house.description;
        }
      }

      if(gameState.location === 'currycorner'){ // East, South
        if (direction === 'east') {
          newLocation = 'whitehouse';
          output = locations.whitehouse.description;
        }
        if (direction === 'south') {
          newLocation = 'gardencentre';
          output = locations.gardencentre.description;
        }
      }

      if(gameState.location === 'gardencentre'){ // North
        if (direction === 'north') {
          newLocation = 'currycorner';
          output = locations.currycorner.description;
        }
      }

      if(gameState.location === 'highstreet'){ // North, East, South
        if (direction === 'north') {
          newLocation = 'whitehouse';
          output = locations.whitehouse.description;
        }
        if (direction === 'east') {
          newLocation = 'duckpond';
          output = locations.duckpond.description;
        }
        if (direction === 'south') {
          newLocation = 'townhall';
          output = locations.townhall.description;
        }
      }

      if(gameState.location === 'duckpond'){ // West, North
        if (direction === 'west') {
          newLocation = 'highstreet';
          output = locations.highstreet.description;
        }
        if (direction === 'north') {
          newLocation = 'house';
          output = locations.house.description;
        }
      }

      if(gameState.location === 'townhall'){ // North
        if (direction === 'north') {
          newLocation = 'highstreet';
          output = locations.highstreet.description;
        }
      }

      // check if the newLocation has any items
      if (locations[newLocation]?.item.length) {
        // check if the item is already in the inventory
        if (!gameState.inventory.includes(locations[newLocation].item)) {
          // if the item is coins, check if spade is in the inventory.  If spade is in the inventory then the player has spent the coins to buy the spade so cannot see them in the house
          if (locations[newLocation].item === 'coins' && gameState.inventory.includes('spade')) {
            //do nothing
          } else {
            output += `\n👀 Looking around you spot: ${locations[newLocation].item}.`;
          }
        }
      }

      setGameState({ ...gameState, location: newLocation });

      if (!output) {
        output = 'You wander around confused for a little while, you really should drink less whiskey.';
      }

    }

    // #region Take Command
    if (command === 'take') {
      const item = args[1].toLowerCase(); // get the item they want to take
      if (!item) {  // if they haven't specified an item then return a message saying take what?
        output = 'Take what?';
      }

      if(item === 'coins' && gameState.location === 'house') {
        output = 'You pick up the coins 🪙🪙.  Maybe you could buy some spice with this, or even some sweets?  No, best save it for something important.';
        setGameState({ ...gameState, inventory: [...gameState.inventory, item] });
      }

      if(item === 'spade' && gameState.location === 'gardencentre') {
        output = "You pick up the spade without trying to pay for it.  The owner Gary with the big lugs kicks his football at you and it thunks into the side of your head.  Sadly, you don't recover. 🪦☠️";
        setGameState({ ...gameState, location: 'dead' });
      }

      if(item === 'bread' && gameState.location === 'duckpond') {
        output = 'You pick up the bread 🍞.  You could feed the ducks with this, if you are into that type of thing.';
        setGameState({ ...gameState, inventory: [...gameState.inventory, item] });
      }

      if(item === 'marrow' && gameState.location === 'shed') {
        output = 'You pick up the marrow.  It is a fine specimen, you could win the big vegetable show with this. 🏆';
        setGameState({ ...gameState, inventory: [...gameState.inventory, item] });
      }

      if (!output) {
        output = 'You cannot take that.';
      }

    }
    // #endregion

    // #region use Command
    if (command === 'use') {
      const item = args[1]; 
      if (!item) {  // if they haven't specified an item then return a message saying use what?
        output = 'Use what?';
      }

      if(item === 'coins' && gameState.location === 'gardencentre') {
        // they buy the spade, so add it to the inventory and remove the coins from the inventory, set an output message
        output = 'You hand over the coins and take the spade.  Gary with the big lugs gives you a nod of approval. 🙋‍♂️';
        let newGameState = structuredClone(gameState);
        newGameState.inventory.push('spade');
        newGameState.inventory = newGameState.inventory.filter((i: string) => i !== 'coins');
        setGameState(newGameState);
      }

      if(item === 'spade' && gameState.location === 'garden') {
        // they use the spade on the compost heap and find a key, add the key to the inventory and set an output message
        let newGameState = structuredClone(gameState);
        newGameState.inventory.push('key');
        setGameState(newGameState);
        output = 'You dig around in the compost heap with the spade and find a key.  You take the key. 🗝️';
      }

      if(item === 'key' && gameState.location === 'allotment') {
        // they use the key to unlock the shed, set an output message and set the shed to unlocked
        output = 'You use the key to unlock the shed.';
        setGameState({ ...gameState, isShedUnlocked: true });
      }

      if(item === 'bread' && gameState.location === 'duckpond') {
        // they feed the ducks, an old lady spots Porky feeding the ducks and gives him a stern telling off, and then beats him to death with her handbag.  Game over
        output = 'You feed the ducks the bread. 🦆🍞  An old lady spots you and gives you a stern telling off, then beats you to death with her handbag. 👜  Game over. 🪦☠️';
        setGameState({ ...gameState, location: 'dead' });
      }

      if(item === 'marrow' && gameState.location === 'townhall') {
        // they place the marrow on the table at the town hall, the game is over, set an output message and set the location to win
        output = 'You place the marrow on the table at the town hall.  The game is over, you have won.  I guess you want a clue now?  Here goes then.  Matthew Corbetts replacement is a clue to the name of the murderer.';
        setGameState({ ...gameState, location: 'win' });
      }

      // if there is no output message, then the item is not used in the current location, so set an output message to say that
      if (!output) {
        output = 'You cannot use that here.';
      }
    }
    // #endregion

    // #region Inventory Command
    if (command === 'inventory') {
      output = `Inventory: ${gameState.inventory.join(', ')}`;
    }
    // #endregion

    // #region Help Command
    if (command === 'help') {
      output = commands[command];
    }
    // #endregion

    // #region Date Command
    if (command === 'date') {
      output = commands[command];
    }
    // #endregion

    // #region Hello Command
    if (command === 'hello') {
      output = commands[command];
    }
    // #endregion

    // #region Clear Command
    if (input === 'clear') {
      setCommandHistory([]);
    } else {
      setCommandHistory((prev) => [...prev, `> ${input}`, `\n${output}`]);
    }
    // #endregion
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCommand(currentInput);
      setCurrentInput('');
    }
  };

  useEffect(() => {
    terminalEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [commandHistory]);

  const terminalClass = `terminal ${isRebooting ? 'rebooting' : ''}`;

  return (
    <div className={terminalClass} onClick={() => terminalEndRef.current?.focus()}>
      {commandHistory.map((line, index) => (
        <div key={index} className="terminal-line">{line}</div>
      ))}
      <div className="input-line">
        <span className="prompt">&gt;</span>
        <input
          ref={terminalEndRef}
          type="text"
          value={currentInput}
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyPress={handleKeyPress}
          className="input-field"
          autoFocus
        />
      </div>
    </div>
  );
};

